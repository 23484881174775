import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'

import useFullWidth, { UseFullWidthProps } from '../../design-tokens/useFullWidth'
import CircularProgress from '../CircularProgress'
import Flex from '../Flex'

interface Props extends UseFullWidthProps {
  isLoading?: boolean
  noLoader?: boolean
  children?: React.ReactNode
}

type ProcessingRef = HTMLElementTagNameMap['div']

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  children: ({ isLoading }: Props) => ({
    pointerEvents: isLoading ? 'none' : 'auto',
    opacity: isLoading ? 0.25 : 1,
  }),
})

// eslint-disable-next-line no-shadow
export const Processing = React.forwardRef<ProcessingRef, Props>(function Processing(
  { isLoading, noLoader = false, children, fullWidth = false },
  ref,
) {
  const classes = useStyles({ isLoading })
  const { fullWidthClass } = useFullWidth({ fullWidth })
  return (
    <div className={classNames(classes.root, fullWidthClass)} ref={ref}>
      <div className={classes.children}>{children}</div>
      {isLoading && (
        <Flex alignItems="center" justifyContent="center" className={classes.overlay}>
          {!noLoader && <CircularProgress />}
        </Flex>
      )}
    </div>
  )
})

export default Processing

import { default as MuiListItem, ListItemProps } from '@mui/material/ListItem'
import * as React from 'react'

type ListItemRef = HTMLElementTagNameMap['li']

// eslint-disable-next-line no-shadow
const ListItem = React.forwardRef<ListItemRef, Omit<ListItemProps, 'button'>>(function ListItem(
  { disableGutters = true, ...props },
  ref,
) {
  return <MuiListItem {...props} ref={ref} disableGutters />
})

export default ListItem

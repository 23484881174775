import { Stack } from '@mui/material'
import { CreateCSSProperties } from '@mui/styles'
import * as React from 'react'
import deepmerge from 'deepmerge'

import resolveLayoutSpacing from '../../design-tokens/theme-utils/resolve-layout-spacing'
import { FontSizing, theme } from '../../design-tokens/theme'
import { UseSpacingProps } from '../../design-tokens/useSpacing'
import { getResponsiveValue } from '../../design-tokens/theme-utils'
import resolveFontSizing from '../../design-tokens/theme-utils/resolve-font-sizing'
import getColorValue from '../../design-tokens/theme-utils/get-color-value'
import { SvgIconProps } from '../SvgIcon'

import { Icons } from '.'

export type IconType = (typeof Icons)[keyof typeof Icons] | React.FC<React.PropsWithChildren<SvgIconProps>>

const getSpacingProperties = (props: UseSpacingProps) => {
  return deepmerge.all([
    getResponsiveValue('margin', resolveLayoutSpacing(theme)(props.margin), theme),
    getResponsiveValue('marginBottom', resolveLayoutSpacing(theme)(props.marginBottom), theme),
    getResponsiveValue('marginLeft', resolveLayoutSpacing(theme)(props.marginLeft), theme),
    getResponsiveValue('marginRight', resolveLayoutSpacing(theme)(props.marginRight), theme),
    getResponsiveValue('marginTop', resolveLayoutSpacing(theme)(props.marginTop), theme),
    getResponsiveValue('padding', resolveLayoutSpacing(theme)(props.padding), theme),
    getResponsiveValue('paddingBottom', resolveLayoutSpacing(theme)(props.paddingBottom), theme),
    getResponsiveValue('paddingLeft', resolveLayoutSpacing(theme)(props.paddingLeft), theme),
    getResponsiveValue('paddingRight', resolveLayoutSpacing(theme)(props.paddingRight), theme),
    getResponsiveValue('paddingTop', resolveLayoutSpacing(theme)(props.paddingTop), theme),
  ]) as CreateCSSProperties
}

interface Props extends UseSpacingProps {
  color?: string
  icon: IconType
  size?: FontSizing | Array<FontSizing>
  className?: string
  onClick?: (event: React.PointerEvent<Element> | React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

// The default element type of the Flex component is 'div' and we don't allow the change of this prop
type IconRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
export const Icon = React.forwardRef<IconRef, Props>(function Icon(
  { icon: Component, className, color: themeColor = 'text.primary', size = 'default', onClick, ...props },
  ref,
) {
  const fontSize = getResponsiveValue('fontSize', resolveFontSizing(theme)(size), theme)
  const color = themeColor ? getColorValue(theme)({ color: themeColor }) : 'initial'
  const spacingProperties = getSpacingProperties(props) || {}

  return (
    <Stack alignItems="center" sx={{ fontSize }} ref={ref}>
      <Component sx={{ color, ...spacingProperties }} fontSize="inherit" onClick={onClick} className={className} />
    </Stack>
  )
})

export default Icon

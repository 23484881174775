import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'
import { CSSProperties } from 'react'

import { filterProps } from '../../design-tokens/design-utils'
import useColor from '../../design-tokens/useColor'
import useFontWeight from '../../design-tokens/useFontWeight'
import useNoWrap, { UseNoWrapProps } from '../../design-tokens/useNoWrap'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'
import useTextAlign, { UseTextAlignProps } from '../../design-tokens/useTextAlign'
import useTextTransform, { UseTextTransformProps } from '../../design-tokens/useTextTransform'

export const sizeVariants = {
  'x-small': {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  small: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  normal: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.03125rem',
  },
}

const styles = {
  withPointer: {
    cursor: 'pointer',
  },
}

const useStyles = makeStyles({ ...styles, ...sizeVariants })

type TextComponents = 'p' | 'span' | 'a' | 'div' | 'dt' | 'dd' | 'label'
type Size = keyof typeof sizeVariants

export interface TextProps extends UseSpacingProps, UseTextAlignProps, UseTextTransformProps, UseNoWrapProps {
  color?: string
  useHover?: boolean
  showPointer?: boolean
  size?: Size
  fontWeight?: 'bold' | 'normal'
  className?: string
  is?: TextComponents
  style?: CSSProperties
  onClick?: () => void
  children?: React.ReactNode
}

// The any below is not a problem, because we are only passing down the reference of the parent component
// The reference is already from type of the Component, definied through of the prop 'is'
// eslint-disable-next-line no-shadow
export const Text = React.forwardRef<any, TextProps>(function Text(
  {
    color = 'text.primary',
    useHover = false,
    size = 'normal',
    fontWeight = 'normal',
    textTransform = 'none',
    className,
    is = 'p',
    textAlign,
    noWrap,
    showPointer = false,
    ...rest
  },
  ref,
) {
  const classes = useStyles()
  const { colorClass } = useColor({ color, useHover })
  const { fontWeightClass } = useFontWeight({ fontWeight })
  const { textAlignClass } = useTextAlign({ textAlign })
  const { textTransformClass } = useTextTransform({ textTransform })
  const { noWrapClass } = useNoWrap({ noWrap })
  const { spacingClass } = useSpacing(rest)
  const Component = is

  return (
    <Component
      className={classNames(
        className,
        classes[size],
        colorClass,
        spacingClass,
        fontWeightClass,
        textAlignClass,
        textTransformClass,
        noWrapClass,
        {
          [classes.withPointer]: showPointer,
        },
      )}
      ref={ref}
      {...filterProps(rest, ...UseSpacingProp)}
    />
  )
})

export default Text

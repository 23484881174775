import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import BaseLink, { BaseLinkProps } from '../BaseLink'
import Text, { TextProps } from '../Text'

export interface Props extends BaseLinkProps, Pick<TextProps, 'useHover' | 'size' | 'fontWeight' | 'onClick'> {
  color?: 'secondary' | 'subtle' | 'white'
}

type LinkRef = HTMLElementTagNameMap['a']

const useStyles = makeStyles({
  inline: {
    display: 'inline',
  },
})

function mapColor(color: Exclude<Props['color'], undefined>): string {
  switch (color) {
    case 'subtle':
      return 'subtleText'
    case 'secondary':
      return 'secondary.main'
    case 'white':
      return 'white'
    default:
      return assertNever(color)
  }
}

// eslint-disable-next-line no-shadow
export const Link = React.forwardRef<LinkRef, Props>(function Link(
  {
    color = 'secondary',
    className,
    useHover = true,
    size = 'normal',
    fontWeight = 'normal',
    children,
    external = false,
    ...rest
  },
  ref,
) {
  const classes = useStyles()

  return (
    <BaseLink external={external} {...rest} ref={ref}>
      <Text
        is="span"
        fontWeight={fontWeight}
        color={mapColor(color)}
        useHover={useHover}
        size={size}
        className={classes.inline}
      >
        {children}
      </Text>
    </BaseLink>
  )
})

export default Link

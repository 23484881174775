import { hydrateRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import {
  ThemeProvider as LegacyStyledComponentsThemeProvider,
  DefaultTheme,
} from '@eversports/react-components/design-tokens/styled-components'
import StyleProvider from '@eversports/react-components/design-tokens/StyleProvider'
import createEmotionCache from '@eversports/react-components/design-tokens/create-emotion-cache'
import EmotionCacheProvider from '@eversports/react-components/design-tokens/EmotionCacheProvider'
import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { DEFAULT_LANGUAGE, getLanguageFromLanguageWithLocale, Language } from '@eversports/language'
import AmplitudeProvider, { TrackingStrategy } from '@eversports/amplitude-react/AmplitudeProvider'
import Baseline from '@eversports/react-components/design-tokens/Baseline'

import createApolloClient, { CreateApolloOptions } from './create-apollo-client'
import createStyleContextClient from './create-style-context-client'
import SettingsContext from './SettingsContext'
import { getWindow } from './window'
import CookieProviderClient from './cookie-provider/CookieProviderClient'

// Although this is a side-effect that get's implicitly called by importing the function below this should
// be an acceptable trade-off
if (module.hot) {
  module.hot.accept()
}

interface Options {
  App: React.FC<React.PropsWithChildren<unknown>>
  basename?: string
  getLocalizationProvider: (language: Language) => Promise<React.FC<React.PropsWithChildren<unknown>>>
  apolloOptions: CreateApolloOptions
  amplitudeOptions?: {
    trackingStrategy?: TrackingStrategy
  }
}

const cache = createEmotionCache()

async function hydrateReactClient({
  App,
  basename,
  getLocalizationProvider,
  apolloOptions,
  amplitudeOptions,
}: Options) {
  const apolloClient = createApolloClient({ ...apolloOptions, state: getWindow().__APOLLO_STATE__ })
  const language = getLanguageFromLanguageWithLocale(navigator.language) || DEFAULT_LANGUAGE
  const LocalizationProvider = await getLocalizationProvider(language)

  const styleContext = createStyleContextClient()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Custom window type
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const settings = { host: window.location.host, protocol: window.location.protocol, language }
  const amplitudeProjectApiKey = getWindow().env.AMPLITUDE_PROJECT_API_KEY

  const rootElement = document.getElementById('root')

  if (!rootElement) {
    throw new Error('Root element not found')
  }

  hydrateRoot(
    rootElement,
    <LocalizationProvider>
      <BrowserRouter basename={basename}>
        <HelmetProvider>
          <ApolloProvider client={apolloClient}>
            <AmplitudeProvider
              amplitudeProjectApiKey={amplitudeProjectApiKey}
              trackingStrategy={amplitudeOptions?.trackingStrategy}
            >
              <SettingsContext settings={settings}>
                <EmotionCacheProvider value={cache}>
                  <StyleProvider generateClassName={styleContext.generateClassName} theme={styleContext.theme}>
                    <LegacyStyledComponentsThemeProvider theme={styleContext.theme as DefaultTheme}>
                      <CookieProviderClient allowedCookies={['isDrawerOpen']}>
                        <Baseline />
                        <App />
                      </CookieProviderClient>
                    </LegacyStyledComponentsThemeProvider>
                  </StyleProvider>
                </EmotionCacheProvider>
              </SettingsContext>
            </AmplitudeProvider>
          </ApolloProvider>
        </HelmetProvider>
      </BrowserRouter>
    </LocalizationProvider>,
  )
}

export default hydrateReactClient

import MuiTextField, { OutlinedTextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import { PyroFormValues, usePyroField } from 'pyro-form'
import * as React from 'react'

// The default element type of MUI's TextField is 'div' and we don't allow the change of this prop
export type TextFieldRef = HTMLElementTagNameMap['div']

const useStyles = makeStyles({
  textFieldRoot: {
    height: 48,
  },
  textFieldInput: {
    boxSizing: 'border-box',
    height: '100%',
  },
  inputLabelRoot: {
    lineHeight: 'inherit',
  },
})

export interface TextFieldProps<Values extends PyroFormValues> extends Partial<MuiTextFieldProps> {
  name: Extract<keyof Values, string>
  ref?: React.Ref<TextFieldRef>
}

// eslint-disable-next-line no-shadow
export const TextField = React.forwardRef(function TextField<Values extends PyroFormValues = any>(
  { name, variant = 'outlined', ...muiProps }: TextFieldProps<Values>,
  ref?: React.Ref<TextFieldRef>,
) {
  const classes = useStyles()
  const { meta, core } = usePyroField<Values, Extract<keyof Values, string>>(name)
  return (
    <MuiTextField
      ref={ref}
      {...core}
      {...muiProps}
      helperText={meta.touched ? meta.error : muiProps.helperText}
      InputProps={{
        ...muiProps.InputProps,
        classes: {
          root: classes.textFieldRoot,
          input: classes.textFieldInput,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabelRoot,
        },
        ...muiProps.InputLabelProps,
      }}
      error={meta.touched ? !!meta.error : undefined}
      name={name}
      variant={variant}
    />
  )
})

export default TextField

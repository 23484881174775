import { darken, PaletteColor } from '@mui/material/styles'
import { TypeText } from '@mui/material/styles/createPalette'
import type { PropertiesHyphenFallback } from 'csstype'

import { Breakpoint } from './breakpoint'
import setSpacing from './spacing'
import { css, RuleSet } from './styled-components'
import { BorderRadii, Borders, FontSizing, getBorderFromString, LayoutSpacing, Palette, Space, Theme } from './theme'

export function filterProps<T extends Record<string, unknown>, K extends keyof T>(
  object: T,
  ...filter: Array<K>
): Omit<T, K> {
  const ret: Partial<T> = {}
  const keys = Object.keys(object) as Array<K>
  for (const key of keys) {
    if (!filter.includes(key)) {
      ret[key] = object[key]
    }
  }
  return ret as Omit<T, K>
}

type FalseValue = false | undefined | null

const renderMediaQuery = (query: number, content: string | Array<string>): string => `
  @media (max-width: ${query}px) {
    ${(Array.isArray(content) ? content : [content]).join(' ')}
  }
`

export const mediaQuery =
  (breakpoint: Breakpoint) =>
  (args: TemplateStringsArray): ThemeGetterFunction =>
  (props) =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    renderMediaQuery(props.theme.breakpoints.values[breakpoint], `${css(args)}`)

const renderCSSProperty = (property: keyof PropertiesHyphenFallback, value: string | number | FalseValue): string =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  `${property}: ${value};`

export const responsiveValue =
  <T extends string | number>(
    attribute: keyof PropertiesHyphenFallback,
    value: T | Array<T> | FalseValue,
  ): ThemeGetterFunction =>
  (props) => {
    if (!value && value !== 0) {
      return ''
    }

    // Resolve single value instantly
    if (!Array.isArray(value)) {
      return renderCSSProperty(attribute, value)
    }

    // Resolve single value in array instantly too
    if (value.length === 1) {
      return renderCSSProperty(attribute, value[0])
    }

    // Aggregate default and responsive value
    let result = renderCSSProperty(attribute, value[value.length - 1])
    result += value
      .map((singleValue, index) => {
        const breakpointKey = props.theme.breakpoints.keys[index]
        return renderMediaQuery(
          props.theme.breakpoints.values[breakpointKey],
          renderCSSProperty(attribute, singleValue),
        )
      })
      .reverse()
      .join('')
    return result
  }

interface Styles {
  [ruleOrSelector: string]: string | number | Styles
}

type ThemeGetterFunction<
  T extends { theme: Theme } = { theme: Theme },
  R = ReturnType<typeof css> | string | FalseValue | Styles,
> = (props: T) => R

/// //////////////////////////////////
/// //// FUNCTIONALITY GETTER ////////
/// //////////////////////////////////

/**
 * Insert Padding
 */

export const setPadding =
  (...values: Array<Space>): ThemeGetterFunction =>
  (props) =>
    setSpacing('padding', ...values.map((v) => props.theme.space[v]))

/**
 * Insert Margin
 */

export const setMargin =
  (...values: Array<Space>): ThemeGetterFunction =>
  (props) =>
    setSpacing('margin', ...values.map((v) => props.theme.space[v]))

/**
 * Insert border radius
 */
export const setBorderRadius =
  (name: BorderRadii = 'default'): ThemeGetterFunction =>
  (props) =>
    `border-radius: ${props.theme.borderRadii[name]};`

/**
 * Insert border
 */
export const setBorder = (name: Borders = 'default'): string => getBorderFromString(name)

/**
 * Insert font size
 */
export const setFontSize =
  (name: FontSizing = 'default'): ThemeGetterFunction =>
  (props) =>
    `font-size: ${props.theme.fontSizing[name]};`

/**
 * Get shadow from generation file, 0 is no shadow
 */
export const setBoxShadow =
  (level: number | 'none' = 1): ThemeGetterFunction =>
  (props) =>
    `box-shadow: ${props.theme.shadows[level === 'none' ? 0 : level]};`

/// //////////////////////////////////
/// //////// VALUE GETTER ////////////
/// //////////////////////////////////

/**
 * Get value from theme
 */
export const getValueFromTheme =
  <T extends Theme, K extends keyof T, V extends keyof T[K] & string>(
    key: K,
    value: V,
  ): ThemeGetterFunction<{ theme: T }> =>
  (props) =>
    props.theme[key][value] as unknown as string | RuleSet<object> | FalseValue | Styles

/**
 * Get spacing value for layout and position management
 */
export const getLayoutSpacingValue = (name: LayoutSpacing = 'default'): ThemeGetterFunction =>
  getValueFromTheme('layoutSpacing', name)

/**
 * Get spacing value for primitives only
 */
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match
export const getSpacingValue__INTERNAL__ = (spacing: Space = 'none'): ThemeGetterFunction =>
  getValueFromTheme('space', spacing) as ThemeGetterFunction

/**
 * Get color from theme
 */
export const getColorValue =
  (colorPath: string): ThemeGetterFunction =>
  (props) => {
    if (!colorPath) return undefined

    const [colorKey, colorVariant] = colorPath.split('.')
    const color = props.theme.palette[colorKey as Palette]

    if (color) {
      if (typeof color === 'string') {
        return color
      }
      if (typeof color === 'object') {
        if (isPaletteColor(color) && color[colorVariant as keyof PaletteColor]) {
          return color[colorVariant as keyof PaletteColor]
        }
        if (isTypeText(color) && color[colorVariant as keyof TypeText]) {
          return color[colorVariant as keyof TypeText]
        }

        console.error(`You passed an invalid color variant. color: ${colorKey}, variant: ${colorVariant}`)
        return undefined
      }
    }

    console.error(`You passed an invalid color: ${colorPath}`)
    return undefined
  }

/**
 * Get darkened Color from theme
 */
export const getColorDarkenedValue =
  (color: string, amount = 0.1): ThemeGetterFunction =>
  (props) => {
    const paletteColor = getColorValue(color)(props)
    return paletteColor ? darken(paletteColor as unknown as string, amount) : undefined
  }

/// ///////////////////////////////////
/// //////// PROP MODIFIER ////////////
/// ///////////////////////////////////

/**
 * Checks if the color is type of PaletteColor
 */
const isPaletteColor = (color: PaletteColor | TypeText): color is PaletteColor => (color as PaletteColor) !== undefined
/**
 * Checks if the color is type of TypeText
 */
const isTypeText = (color: PaletteColor | TypeText): color is TypeText => (color as TypeText) !== undefined
